import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PaginaInicial from './paginas/PaginaInicial';
import MeusAnuncios from './paginas/MeusAnuncios';
import { AuthProvider } from './servicos/AuthContext';
import PrivateRoute from './PrivateRoute';
import CriarAnuncio from './paginas/CriarAnuncio';
import Detalhes from './paginas/Detalhes';
import RespostasAnuncio from './paginas/RespostasAnuncio'
import Encerrados from './paginas/Encerrados/Encerrados';
import Venda from './paginas/Venda';
import MeuEstoque from './paginas/MeuEstoque';
import MinhaLoja from './paginas/MinhaLoja';

function App() {
  return (
    <div className="App">
  <AuthProvider>
            <Router>
              
                <Routes>
                    <Route path="/" element={<PaginaInicial />} />
                    <Route element={<PrivateRoute />}>
                        <Route path="/meus-anuncios" element={<MeusAnuncios />} />
                        <Route path="/meus-anuncios/criar-anuncio" element={<CriarAnuncio />} />
                        <Route path="/meus-anuncios/detalhes/:ad_id" element={<Detalhes />} />
                        <Route path="/meus-anuncios/detalhes/respostas-anuncio/:ad_id" element={<RespostasAnuncio />} />
                        <Route path="/venda/:ad_id" element={<Venda />} />
                        <Route path="/encerrados" element={<Encerrados />} />
                        <Route path="/meu-estoque" element={<MeuEstoque />} />
                        <Route path="/minha-loja/:seller_id" element={<MinhaLoja />} />
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    </div>
  );
}

export default App;
